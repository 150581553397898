import {useState, useEffect} from 'react';
import SettingProfile from './SettingProfile.js';
import SettingNotion from './SettingNotion.js';
import SettingReport from './SettingReport.js';
import SettingAdvertisement from './SettingAdvertisement.js';
import Loading from '../Loading'; // 로딩 컴포넌트 임포트
import LoadableComponent from '../LoadableComponent'; // 로딩 관리 컴포넌트 임포트
import SettingSecession from './SettingSecession.js';
import SettingNoShowPhone from './SettingNoShowPhone.js';

function SettingMain(){

	const [profileLoaded, setProfileLoaded] = useState(false);
	const [notionLoaded, setNotionLoaded] = useState(false);
	const [reportLoaded, setReportLoaded] = useState(false);
	const [advertisementLoaded, setAdvertisementLoaded] = useState(false);
	
	const [allComponentsLoaded, setAllComponentsLoaded] = useState(false);

	useEffect(() => {
		// 모든 컴포넌트가 로딩되었는지 확인
		setAllComponentsLoaded(profileLoaded && notionLoaded && reportLoaded);
	},[profileLoaded, notionLoaded, reportLoaded]);
	
	return(
	    <div style={{marginBottom:'5rem'}}>
		{!allComponentsLoaded && <Loading loading={!allComponentsLoaded} />} {/* 로딩 중 표시 */}
		<LoadableComponent Component={SettingProfile} onLoad={() => setProfileLoaded(true)} />
		<LoadableComponent Component={SettingNoShowPhone} onLoad={() => setProfileLoaded(true)} />
		<LoadableComponent Component={SettingNotion} onLoad={() => setNotionLoaded(true)} />
		<LoadableComponent Component={SettingReport} onLoad={() => setReportLoaded(true)} />
		<LoadableComponent Component={SettingAdvertisement} onLoad={() => setAdvertisementLoaded(true)} />
		<LoadableComponent Component={SettingSecession} onLoad={() => setReportLoaded(true)} />
	    </div>
	);
}

export default SettingMain;
