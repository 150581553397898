import { Table, Pagination, Dropdown, Image } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { getStatusLabel, formatSignUpTime, formatPhoneNumber } from './utils';

function AdminReportCheck() {
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalReports, setTotalReports] = useState(0);
  const [reportsPerPage, setReportsPerPage] = useState(50); // 초기값 50
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS; // Express API 주소
  const token = localStorage.getItem('token');


  // 데이터를 가져오는 함수
  useEffect(() => {
    fetchReports(currentPage, reportsPerPage);
  }, [currentPage, reportsPerPage]);

  const fetchReports = async (page, limit) => {
    try {
      const response = await axios.post(`${apiUrl}/ReportUser`, {
	page: page,
        limit: limit,
	token: token,
      });
      setReports(response.data.reports);
      setTotalReports(response.data.total);
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const totalPages = Math.ceil(totalReports / reportsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleReportsPerPageChange = (newLimit) => {
    setReportsPerPage(newLimit);
    setCurrentPage(1); // 페이지를 처음으로 초기화
  };

  return (
    <div className="container" style={{maxWidth: '100%'}}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>신고 관리</h5>
        <p className="mb-0">총 신고 인원: {totalReports}명</p>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <p>사용자 신고 내용을 확인하고 관리하세요.</p>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{fontSize: '0.8rem'}}>
            페이지당 {reportsPerPage}개 표시
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {[50, 100, 150].map((limit) => (
              <Dropdown.Item
                key={limit}
                onClick={() => handleReportsPerPageChange(limit)}
              >
                {limit}개
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>UID</th>
            <th>닉네임</th>
            <th>신고 메시지</th>
            <th>신고 이유</th>
            <th>신고 날짜</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report, index) => (
            <tr key={index}>
              <td>{report.uid}</td>
              <td>{report.nickname}</td>
	      <td>
		  {report.message.startsWith("https://innerong-profile-image.s3") ? (
			  <Image width={90} src={report.message} alt="신고 메시지 이미지" />
		  ):(
			  report.message
		  )}
	      </td>
              <td>{report.reason}</td>
              <td>{new Date(report.reportAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination className="justify-content-center">
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
}

export default AdminReportCheck;

