import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Form, Pagination } from 'react-bootstrap';

function AdminStopUser() {
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [filterState, setFilterState] = useState('W+B'); // 기본값: 둘 다
  const [totalUsers, setTotalUsers] = useState(0); // 총 사용자 수
  const [itemsPerPage, setItemsPerPage] = useState(50); // 한 페이지당 표시 개수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS; // 환경변수로 API 주소 관리

  // 사용자 데이터를 가져오는 함수
  const fetchStoppedUsers = async (stateFilter, page, limit) => {
    const states = stateFilter === 'W+B' ? ['B', 'W'] : [stateFilter]; // 상태 필터링
    try {
      const response = await axios.post(`${apiUrl}/StopUser`, {
        states,
        page,
        limit,
      });
      setBlockedUsers(response.data.users);
      setTotalUsers(response.data.total); // 총 사용자 수 설정
    } catch (error) {
      console.error('Error fetching stopped users:', error);
    }
  };

  // 드롭다운 상태 변경 시 데이터 로드
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilterState(selectedFilter);
    setCurrentPage(1); // 페이지를 1로 초기화
    fetchStoppedUsers(selectedFilter, 1, itemsPerPage);
  };

  // 한 페이지당 표시 개수 변경
  const handleItemsPerPageChange = (e) => {
    const selectedLimit = parseInt(e.target.value, 10);
    setItemsPerPage(selectedLimit);
    setCurrentPage(1); // 페이지를 1로 초기화
    fetchStoppedUsers(filterState, 1, selectedLimit);
  };

  // 페이지 변경
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchStoppedUsers(filterState, pageNumber, itemsPerPage);
  };

  useEffect(() => {
    fetchStoppedUsers(filterState, currentPage, itemsPerPage); // 초기 데이터 로드
  }, []);

  // 총 페이지 수 계산
  const totalPages = Math.ceil(totalUsers / itemsPerPage);

  return (
    <div style={{ marginTop: '20px' }}>
      <h3>정지 및 경고 사용자 목록</h3>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        {/* 좌측: 상태 드롭다운 */}
        <Form.Select
          value={filterState}
          onChange={handleFilterChange}
          style={{ fontSize: '0.8rem', width: '150px' }}
        >
          <option value="W+B">경고 + 정지</option>
          <option value="B">정지</option>
          <option value="W">경고</option>
        </Form.Select>
        {/* 우측: 한 페이지당 표시 개수 */}
        <div>
          <p style={{ fontSize: '0.8rem', margin: 0 }}>
            총 <strong>{totalUsers}</strong>명
          </p>
        </div>
        <Form.Select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          style={{ fontSize: '0.8rem', width: '100px' }}
        >
          <option value="50">50개</option>
          <option value="100">100개</option>
          <option value="150">150개</option>
        </Form.Select>
      </div>
      <Table striped bordered hover style={{ fontSize: '0.8rem' }}>
        <thead>
          <tr>
            <th>UID</th>
            <th>Main Location</th>
            <th>Sub Location</th>
            <th>생일</th>
            <th>성별</th>
            <th>닉네임</th>
            <th>메시지</th>
            <th>가입시간</th>
            <th>핸드폰</th>
            <th>이름</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          {blockedUsers.map((user) => (
            <tr key={user.uid}>
              <td>{user.uid}</td>
              <td>{user.mainlo}</td>
              <td>{user.sublo}</td>
              <td>{user.birth}</td>
              <td>{user.sex === 'F' ? '여성' : '남성'}</td>
              <td>{user.nname}</td>
              <td>{user.message}</td>
              <td>{new Date(user.time).toLocaleString()}</td>
              <td>{user.phone}</td>
              <td>{user.rname}</td>
              <td>{user.state === 'B' ? '정지' : '경고'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* 페이지네이션 */}
      <Pagination style={{display:'block'}}>
        {[...Array(totalPages).keys()].map((page) => (
          <Pagination.Item
	    style={{display: 'inline-block', textAlign: 'center'}}
            key={page + 1}
            active={page + 1 === currentPage}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
}

export default AdminStopUser;

