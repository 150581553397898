import axios from 'axios';

const token = localStorage.getItem('token');

// 사용자 상태 라벨 반환
export function getStatusLabel(state) {
  switch (state) {
    case 'Y':
      return '정상';
    case 'W':
      return '경고';
    case 'B':
      return '블록';
    default:
      return '알 수 없음';
  }
}

// 가입 시간 포맷팅
export function formatSignUpTime(timestamp) {
  const date = new Date(timestamp);
  return date.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });
}

// 전화번호 포맷팅
export function formatPhoneNumber(phone) {
  if (!phone) return '없음';
  return phone.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
}

//User State Change
export async function changeUserState(uid, state) {
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

  console.log('uid: ',uid);
  console.log('state: ',state);

  try {
    const response = await axios.post(`${apiUrl}/ChangeUserState`, { uid, state, token });
    if(response.data){
	    alert('변경되었습니다.');
	    return response.data; // 서버의 응답 데이터 반환
    }
  } catch (error) {
    console.error('Error changing user state:', error);
    throw new Error('사용자 상태 변경 중 오류가 발생했습니다.');
  }
}
  

