import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Form, Pagination } from 'react-bootstrap';

function AdminDeleteUser() {
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0); // 총 사용자 수
  const [itemsPerPage, setItemsPerPage] = useState(50); // 한 페이지당 표시 개수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS; // 환경변수로 API 주소 관리
  const token = localStorage.getItem('token');

  // 사용자 데이터를 가져오는 함수
  const fetchDeletedUsers = async (page, limit) => {
    try {
      const response = await axios.post(`${apiUrl}/DeleteUser`, {
        page,
        limit,
	token,
      });
      setDeletedUsers(response.data.users);
      setTotalUsers(response.data.total); // 총 사용자 수 설정
    } catch (error) {
      console.error('Error fetching deleted users:', error);
    }
  };

  // 한 페이지당 표시 개수 변경
  const handleItemsPerPageChange = (e) => {
    const selectedLimit = parseInt(e.target.value, 10);
    setItemsPerPage(selectedLimit);
    setCurrentPage(1); // 페이지를 1로 초기화
    fetchDeletedUsers(1, selectedLimit);
  };

  // 페이지 변경
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchDeletedUsers(pageNumber, itemsPerPage);
  };

  useEffect(() => {
    fetchDeletedUsers(currentPage, itemsPerPage); // 초기 데이터 로드
  }, []);

  // 총 페이지 수 계산
  const totalPages = Math.ceil(totalUsers / itemsPerPage);

  return (
    <div style={{ marginTop: '20px' }}>
      <h3>탈퇴 사용자 목록</h3>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        {/* 좌측: 총 인원 표시 */}
        <div>
          <p style={{ fontSize: '0.8rem', margin: 0 }}>
            총 <strong>{totalUsers}</strong>명
          </p>
        </div>
        {/* 우측: 한 페이지당 표시 개수 드롭다운 */}
        <Form.Select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          style={{ fontSize: '0.8rem', width: '100px' }}
        >
          <option value="50">50개</option>
          <option value="100">100개</option>
          <option value="150">150개</option>
        </Form.Select>
      </div>
      <Table striped bordered hover style={{ fontSize: '0.8rem' }}>
        <thead>
          <tr>
            <th>UID</th>
            <th>User Id</th>
            <th>생일</th>
            <th>탈퇴사유</th>
            <th>탈퇴일</th>
          </tr>
        </thead>
        <tbody>
          {deletedUsers.map((user) => (
            <tr key={user.uid}>
              <td>{user.uid}</td>
              <td>{user.phone}</td>
              <td>{user.birth}</td>
              <td>{user.reason}</td>
              <td>{new Date(user.secessionAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* 페이지네이션 */}
      <Pagination>
        {[...Array(totalPages).keys()].map((page) => (
          <Pagination.Item
            key={page + 1}
            active={page + 1 === currentPage}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
}

export default AdminDeleteUser;

