import {Row, Col, Button, Image,Ratio, Container, Modal} from 'react-bootstrap';
import {CaretRight, PhoneVibrateFill, ChatDotsFill, Pen, XCircleFill, TrashFill, ExclamationCircleFill} from 'react-bootstrap-icons';
import {Link, useNavigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import GoBack from '../GoBack.js';
import axios from 'axios';
import LazyLoad from 'react-lazyload';

const NickName ={
	paddingLeft: '10px',
	fontSize: '1rem',
	fontWeight: 'bold',
	height:'25%'
};

const MyMessage = {
	paddingLeft: '10px',
	fontSize: '0.8rem',
	marginBottom: '10px',
	minHeight: '25%'
};

const SetText = {
	textAlign: 'center',
	fontSize: '0.7rem',
	width: '100%',
	padding: 0,
	margin: 0,
	display: 'grid'
};
const SetImage = {
	width: '100%',
	height: '25px',
	color: '#ef9304',
}
const PreProfileText = {
	width: '100%',
	textAlign: 'center',
	margin:0,
	display: 'inline-block'
};
const PreProfileImage = {
	padding: '5px',
	width: '95%',
	height: '95%',
};
const WideImageStyle = {
	zIndex: 1001,
	position: 'fixed',
	backgroundColor: 'rgba(0, 0, 0, 0.9)',
	width: '100%',
	height: '110%',
	top: 0,
	left: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '0px',
};
const WideBottomButtonStyle = {
	fontSize: '1.4rem', 
	position:'fixed', 
	zIndex: 1002, 
	bottom:'20px',
	width:'100%',
	paddingLeft: '10%',
	paddingRight: '10%',
};
const BottomText = {
	fontSize: '0.8rem',
	color:'white',
};
const DeleteCheckStyle={
	zIndex: 1003,
	top: 0,
	left: 0,
	width: '100vw',
	height: '100vh',
	backgroundColor: 'black',
	opacity: '0.5',
	position: 'fixed'
};
const DeleteCheckBoxStyle={
	zIndex: 1004,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	textAlign: 'center',
	position: 'fixed',
	fontSize: '0.8rem'
};

function MyProfile() {
	
	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

	//이미지 보기
	const [showImage, setShowImage] = useState(false);
	const [wideImageSrc, setWideImageSrc] = useState(null);
	const wideImage = (imageSrc) => {
        	    setShowImage(!showImage);
		    setWideImageSrc(imageSrc);
	}


	//삭제 클릭 시 Alert창 출력
	const [deleteImageSrc, setDeleteImageSrc] = useState(null);
	const [deleteCheck, setDeleteCheck] = useState(false);
	const [deleteComplete, setDeleteComplete] = useState(false);
	const deleteReal = (imageSrc) => {
		setDeleteImageSrc(imageSrc);
		setDeleteCheck(true);
	}
	const handleCancleDelete = () => {
		setDeleteCheck(false);
	};
	//실제 삭제하는 함수
	const deleteRealImage = async() => {
		try{
			const token = localStorage.getItem('token');
			const response = await axios.post(`${apiUrl}/DeleteImage`,{ myToken: token, imageSrc: wideImageSrc });
			await setDeleteCheck(false);
			await setShowImage(false);
			await setWideImageSrc(null);
			await setDeleteComplete(true);
		}catch(error){
			console.log(error);
		}
	};

	const [myImages, setMyImages] = useState([]);

	const ProfileImage = myImages.map((v) => {
	    // UNIX 타임스탬프를 JavaScript Date 객체로 변환
	    const date = new Date(v.time);
    
	    // 년, 월, 일 추출
	    const year = date.getFullYear();
	    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 두 자리로 포맷합니다.
	    const day = String(date.getDate()).padStart(2, '0'); // 일을 두 자리로 포맷합니다.
	
	    // YYYY-MM-DD 형식으로 포맷
	    const formattedDate = `${year}-${month}-${day}`;

	    return (
	        <Col xs={4}>
	            <Row style={PreProfileText}>{formattedDate}</Row>
	       	     <Row>
	                <Ratio aspectRatio="1x1" style={PreProfileImage} onClick={() => wideImage(v.pimage)}>
	                    <Image src={v.pimage} style={{border: 'solid 1px #c0b9b9'}}fluid />
	                </Ratio>
	            </Row>
	        </Col>
	    );
	});

        const [myProfileInfo, setData] = useState(null);
        useEffect(()=>{
                const fetchData = async() => {
                        const token = localStorage.getItem('token');
                        const response = await axios.post(`${apiUrl}/MyProfile`,{ myToken: token }); 
                        await setData(response.data);
			await console.log('HI response.data');
                        await console.log(response.data);
			await setDeleteCheck(false);
			await setShowImage(false);

                };  
                fetchData();
		handleProfileImages();
        },[deleteComplete]);

        //프로필 이미지 받아오기
        const handleProfileImages = async() => {
                try {
                        const token = localStorage.getItem('token');
                        const response = await axios.post(`${apiUrl}/ProfileImages`, { myToken: token});
			setMyImages(response.data.profileImages);
                }catch (error) {
                        console.error('ConnectFriend 요청 중 오류 발생: ', error);
                }
        };


	return (
	<div style={{padding: '10px',paddingTop: '20px'}}>
		{deleteCheck && (
		<div>
			<div style={DeleteCheckStyle}>
			</div>
			<div style={DeleteCheckBoxStyle}>
			    <div className="modal show" style={{ display: 'block', position: 'initial' }}>
				<Modal.Dialog>
				    <Modal.Body>
					프로필을 정말로 삭제하시겠습니까?
				    </Modal.Body>
				    <Modal.Footer>
					<Button variant="secondary" style={BottomText} onClick={deleteRealImage}>삭제</Button>
					<Button variant="warning" style={BottomText} onClick={handleCancleDelete}>취소</Button>
				    </Modal.Footer>
				</Modal.Dialog>
			    </div>
			</div>
		</div>
		)}
		 {showImage && (
                	<div className="overlay" style={WideImageStyle}>
                    	    <img src={wideImageSrc} alt="Wide Image" style={{width:'100%', marginTop: '-25%',backgroundColor: 'white'}} />
			 	<div className="close-btn" style={WideBottomButtonStyle}>
			 	    <div>
			 		    <Button variant="light" style={{ position: 'fixed', top: '10px', left: '10px', fontSize: '0.7rem'}} onClick={() => deleteReal(wideImageSrc)}>
			 			삭제
			 		    </Button>
			 		    <Button variant="light" style={{ position: 'fixed', top: '10px', right: '10px', fontSize: '0.7rem', fontWeight: 'bold'}} onClick={wideImage}>
			 			닫기
			 		    </Button>
			 	    </div>
			 	</div>
                    	
			 </div>
            )}
	    <GoBack />
	    <h3><b style={{marginBottom: '1.3rem'}}>프로필 화면</b></h3>
		<Container style={{textAlign: 'left'}}>
		{myProfileInfo &&(
	   	    <Row>
	      		<Col xs={4} className={""} style={{padding: "0"}}>
			    <Ratio aspectRatio="1x1" onClick={() => wideImage(myProfileInfo.loginUser.pimage)}>
				<Image src={myProfileInfo.loginUser.pimage} style={{padding:0}}thumbnail fluid />
			    </Ratio>
			</Col>
	      		<Col xs={8} style={{paddingLeft: '20px'}}>
			    <Row style={NickName}>{myProfileInfo.loginUser.nname}</Row>
			    <Row style={MyMessage}>{myProfileInfo.loginUser.message}</Row>
			    <Row>
		    		<Col>
				    <Row>
					<PhoneVibrateFill style={SetImage} onClick={() => window.location.href=`tel:${myProfileInfo.loginUser.phone}`}/>
				    </Row>
				    <Row style={SetText}>
					전화
				    </Row>
				</Col>
		    		<Col><Row><ChatDotsFill style={{...SetImage, color: 'rgb(255 155 0 / 35%)'}}/></Row><Row style={SetText}>채팅</Row></Col>
		    		<Col>
				    <Link to='/profileFix' style={{textDecoration: 'none', color: 'black'}}>
				    <Row>
				    	<Pen style={SetImage} />
				    </Row>
				    <Row style={SetText}>수정</Row>
				    </Link>
				</Col>
				<Col>
				    <Link to='/blockUsers' style={{textDecoration: 'none', color: 'black'}}>
				    <Row>
					<ExclamationCircleFill style={SetImage} />
				    </Row>
				    <Row style={SetText}>
					차단<br />목록
				    </Row>
				    </Link>
				</Col>
			    </Row>
	      		</Col>
	    	    </Row>
		)}
	  	    <div className="text-center">
			<Row style={{marginTop:'10px', marginBottom: '10px'}}>
		    	    <Col>
			    <hr style={{paddingLeft: '35%', paddingRight: '35%', display:"inline-block"}}/>
		    	    </Col>
		    	    <Col>이전 프로필</Col>
		    	    <Col>
				<hr style={{paddingLeft: '35%', paddingRight: '35%', display:"inline-block"}}/>
		    	    </Col>
			</Row>
			<LazyLoad height={80} once>
			<Row>
			    {ProfileImage&&(ProfileImage)}
			</Row>
			</LazyLoad>
	  	    </div>
		</Container>
	</div>
	);
}

export default MyProfile;
