import React, { useState, useEffect, useRef } from 'react';
import {Row, Col, Ratio, Image, Button } from 'react-bootstrap';
import {PersonArmsUp} from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import {isEqual} from 'lodash';
import axios from 'axios';

//시간 변경하는 함수
const getTimeAgo = (timestamp) => {
  const now = new Date();
  const then = new Date(timestamp);
  const diff = Math.floor((now - then) / 1000); // difference in seconds
  if(timestamp){
      if (diff < 60) {
        return '방금 전';
      } else if (diff < 3600) {
        return `${Math.floor(diff / 60)}분 전`;
      } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)}시간 전`;
      } else if (diff < 604800) {
        return `${Math.floor(diff / 86400)}일 전`;
      } else {
	const year = then.getFullYear();
	const month = String(then.getMonth() + 1).padStart(2, '0');
	const date = String(then.getDate()).padStart(2, '0');
	return `${year}-${month}-${date}`;
      }
  }else{
      return 'ㅡ';
  }
};

//style 모음
const timeStyle={fontSize: '0.6rem',margin: 0,textAlign: 'left', margin: '10px 0', color: '#999999'};
const memberStyle={fontSize: '0.7rem', margin:0, textAlign: 'left'};
const displayDot = {overflow: 'hidden', textOverflow : 'ellipsis', whiteSpace: 'nowrap',display: 'block',  margin: '1rem 0 0 0'};
const messageStyle = {padding: 0};
const classOpenMessage = {padding: 0, color:'#787878'};
const noDataStyle = {width:'100%', textAlign:'center', padding: '1rem', color: '#999999'};
const containerStyle={
        textAlign: 'left',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderRadius: '1rem',
        backgroundColor: 'white',
	marginTop: '0.5rem',
};
const imageStyle={
        backgroundColor: 'white',
        borderRadius: '1rem',
};



function ChatLocation(props) {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
	const token = localStorage.getItem('token');

	const [mainLo, setMainLo] = useState([]);
	const [subLo, setSubLo] = useState([]);
	const [nowLo, setNowLo] = useState([]);
	const [countResults, setCountResults] = useState([]);

	const [paramsLo, setParamsLo] = useState('myLocation');
	const paramsLoRef = useRef(paramsLo); // 최신 상태값 참조

	useEffect(() => {
	    const fetchData = async () => {
		const response = await axios.post(`${apiUrl}/ChatMyLocationRoom`,{ myToken: token });
		setMainLo(response.data.mainloRooms);
		setSubLo(response.data.subloRooms);
		setNowLo(response.data.locationInfo);
		setCountResults(response.data.countResults);
		console.log('response: ',response);
		console.log('mainLo: ', response.data.mainloRooms);
		console.log('subLo: ', response.data.subloRooms);
	    };
	
	    fetchData();

	},[]);

	useEffect(() => {
		const tempParamsLo = props.paramslo;
		const newParamsLo = isEqual(tempParamsLo, 'oftenLocation') ? 'oftenLocation' : 'myLocation';

		// 상태 업데이트가 중복되지 않도록 조건 추가
		if (paramsLo !== newParamsLo) {
			setParamsLo(newParamsLo);
			paramsLoRef.current = newParamsLo; // useRef로도 최신값 유지
		}
		console.log('paramsLo: ',paramsLo);
	}, [props.paramslo]);



	const mainLoInfo = mainLo.map((v, index) => {
	
	    const countResult = countResults.find(result => result.rid === v.rid);
	    const count = countResult ? countResult.count : 0; // 해당 항목이 없으면 기본값 0

	    return (

	    <div xs={5} key={index} style={{display: 'inline-flex', width: '48%', marginRight: index === 0 ? '4%' :'0', backgroundColor: 'white', borderRadius: '1rem', padding: '0.8rem', border: '1px solid #999999'}}>
		<Link style={{textDecoration: 'none', color: 'black', width: '100%'}} to={'/chatRoom/'+v.rid}>
		<div style={{marginTop:'10px', textAlign: 'left'}}>
		    <div>
			<div style={{textAlign: 'left'}}>
			    <span style={{padding: 0, textAlign: 'center'}}>
				{<span style={{fontWeight:'bold',  textAlign: 'left', padding: '0 5px', fontSize: '12px', whiteSpace: 'normal'}}>{v.roomName}</span>}
			    	{count !== 0 && (
				<span style={{marginLeft: '0.5rem', fontSize: '0.6rem', backgroundColor: '#ffdf00', padding: '2px 5px', borderRadius: '1rem', fontWeight: 'bold'}}>
			    		{count}
				</span>
				)}
		    		<div style={{textAlign: 'left', padding: '0 2px', fontSize: '0.6rem'}}><Image style={{width: '15px'}} src='/userIcon.png' /> {v.userCount}</div>
			    </span>
			</div>
			<div style={displayDot}>
			    {v.latestMessage.mType === 'c' 
				? <div style={classOpenMessage}><PersonArmsUp />모임 개최</div>
				: v.latestMessage.mType === 'i' ? (
				  <div style={classOpenMessage}>이미지</div>
				) : v.latestMessage.message ? (
				  v.latestMessage.message
				) : (
				  <div style={messageStyle}>메시지 없음</div>
				)}
			</div>
		    </div>
		    <div xs={2} style={timeStyle}>
			<span>{getTimeAgo(v.latestMessage.createAt)}</span>
		    </div>
		</div>
		</Link>
	    </div>
	);
	});

	const subLoInfo = subLo.map((v, index) => {
	
	    const countResult = countResults.find(result => result.rid === v.rid);
	    const count = countResult ? countResult.count : 0; // 해당 항목이 없으면 기본값 0

	    return (
	    <div key={index} style={{display: 'inline-flex', width: '48%',marginRight: index === 0 ? '4%' :'0',  backgroundColor: 'white', borderRadius: '1rem', padding: '0.8rem', border: '1px solid #999999'}}>
		<Link style={{textDecoration: 'none', color: 'black', width: '100%'}} to={'/chatRoom/'+v.rid}>
		<div style={{marginTop:'10px', textAlign: 'left'}}>
		    <div>
			<div style={{textAlign: 'left'}}>
			    <span style={{padding: 0, textAlign: 'center'}}>
				{<span style={{fontWeight:'bold',  textAlign: 'left', padding: '0 5px', fontSize: '12px', whiteSpace: 'normal'}}>{v.roomName}</span>}
			       {count !== 0 && (
				<span style={{marginLeft: '0.5rem', fontSize: '0.6rem', backgroundColor: '#ffdf00', padding: '2px 5px', borderRadius: '1rem', fontWeight: 'bold'}}>
			    	    {count}
				</span>
			       )}
		    		<div style={{textAlign: 'left', padding: '0 2px', fontSize: '0.6rem'}}><Image style={{width: '15px'}} src='/userIcon.png' /> {v.userCount}</div>
			    </span>
			</div>
			<div style={displayDot}>
			    {v.latestMessage.mType === 'c' 
				? <div style={classOpenMessage}><PersonArmsUp />모임 개최</div>
				: v.latestMessage.mType === 'i' ? (
				  <div style={classOpenMessage}>이미지</div>
				) : v.latestMessage.message ? (
				  v.latestMessage.message
				) : (
				  <div style={messageStyle}>메시지 없음</div>
				)}
			</div>
		    </div>
		    <div xs={2} style={timeStyle}>
			<span>{getTimeAgo(v.latestMessage.createAt)}</span>
		    </div>
		</div>
		</Link>
	    </div>
	    );
	});

  const getButtonStyle = (isActive) => ({
    backgroundColor: isActive ? '#FFDE59' : 'white',
    border: isActive ? '0' : '1px solid #DDDDDD',
    color: isActive ? 'black' : '#a1a1a1',
    borderRadius: '1rem',
    fontWeight: 'bold',
    textDecoration: 'none',
  }); 


return (
<div>
<div style={{padding: '10px', paddingTop: '20px'}}>
    <Row style={{margin:0, marginTop: '1rem', width: '100%', textAlign: 'left'}}>
	<Col xs={12}>
	    <Link to="/chatting/myLocation" style={{margin: 0, marginRight: '10px', padding: 0, width: '100%'}}>
		<Button variant="link" style={getButtonStyle(isEqual(paramsLo, 'myLocation'))} >우리동네</Button> 
	    </Link>
	    <Link to="/chatting/oftenLocation">
		<Button variant="link" style={getButtonStyle(isEqual(paramsLo, 'oftenLocation'))}  >자주가는동네</Button>
	    </Link>
	</Col>
	<div style={{textAlign: 'left', fontWeight: 'bold', marginTop: '1rem', marginBottom: '0.6rem'}}>
	    <Image src='/markIcon.png' />
	    {nowLo?.length 
		    ? (paramsLo === 'myLocation' ? nowLo[0] : nowLo[1])
		    : '위치인증이 필요해요'}
	</div>
    </Row>
    {/* 내용 */}
    <div style={{ position: 'relative', zIndex: 2 }}>
        <div style={{marginLeft: 0}}>
	    {paramsLo === 'myLocation' ? (
            <div style={{ paddingRight: '0.3rem', width: '100%' }}>
                    {mainLoInfo && mainLoInfo.length > 0 ? mainLoInfo : (
                        <div style={{ paddingTop: '1rem', textAlign: 'center' }}>
                            <Image src='/noLocation.png' style={{ width: '55px', opacity: 0.5 }} />
                            <div style={noDataStyle}>
                                위치 인증을 해주세요
                            </div>
                        </div>
                    )}
            </div>
	    ) : (
            <div style={{ paddingRight: '0.3rem', width: '100%' }}>
                    {subLoInfo && subLoInfo.length > 0 ? subLoInfo : (
                        <div style={{ paddingTop: '1rem', textAlign: 'center' }}>
                            <Image src='/noLocation.png' style={{ width: '55px', opacity: 0.5 }} />
                            <div style={noDataStyle}>
                                위치 인증을 해주세요
                            </div>
                        </div>
                    )}
            </div>
	    )}
        </div>
    </div>
</div>
<div style={{height: '10px', backgroundColor: '#F5F5F5', margin: '1rem 0'}}> </div>
</div>


);

}

export default ChatLocation;
