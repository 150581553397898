import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Dropdown, Table } from 'react-bootstrap';
import { getStatusLabel, formatSignUpTime, formatPhoneNumber } from './utils'

function AdminUserCheck() {
  const [userInfo, setUserInfo] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [usersPerPage, setUsersPerPage] = useState(50); // 초기값: 페이지 당 사용자 수
  const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;

  useEffect(() => {
    fetchUserInfo(currentPage, usersPerPage);
  }, [currentPage, usersPerPage]);

  const fetchUserInfo = async (page, limit) => {
    try {
      const response = await axios.post(`${apiUrl}/UserCheck`, {
        myToken: 'your_jwt_token_here',
        page: page,
        limit: limit,
      });
      setUserInfo(response.data.userInfo);
      setTotalUsers(response.data.totalUsers); // 총 가입인원
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const totalPages = Math.ceil(totalUsers / usersPerPage); // 총 페이지 수

  const handleUsersPerPageChange = (limit) => {
    setUsersPerPage(limit);
    setCurrentPage(1); // 페이지를 첫 번째로 초기화
  };


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h5>총 가입인원: {totalUsers}</h5> {/* 좌측 상단 */}
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{fontSize: '0.8rem'}}>
            페이지당 {usersPerPage}개 표시
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {[50, 100, 150].map((limit) => (
              <Dropdown.Item key={limit} onClick={() => handleUsersPerPageChange(limit)}>
                {limit}개
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown> {/* 우측 상단 */}
      </div>

      <div className="table-container">
        <Table striped bordered hover style={{fontSize: '0.6rem'}}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd' }}>UID</th>
              <th style={{ border: '1px solid #ddd' }}>생일</th>
              <th style={{ border: '1px solid #ddd' }}>성별</th>
              <th style={{ border: '1px solid #ddd' }}>닉네임</th>
              <th style={{ border: '1px solid #ddd' }}>메시지</th>
              <th style={{ border: '1px solid #ddd' }}>가입일</th>
              <th style={{ border: '1px solid #ddd' }}>핸드폰</th>
              <th style={{ border: '1px solid #ddd' }}>이름</th>
              <th style={{ border: '1px solid #ddd' }}>상태</th>
            </tr>
          </thead>
          <tbody>
            {userInfo.map((user) => (
              <tr key={user._id}>
                <td style={{ border: '1px solid #ddd' }}>{user.uid}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.birth}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.sex === 'F' ? '여성' : '남성'}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.nname}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.message}</td>
                <td style={{ border: '1px solid #ddd' }}>{new Date(user.time).toLocaleString()}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.phone}</td>
                <td style={{ border: '1px solid #ddd' }}>{user.rname}</td>
                <td style={{ border: '1px solid #ddd' }}>{getStatusLabel(user.state)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index + 1}
            style={{
              margin: '0 5px',
              backgroundColor: currentPage === index + 1 ? '#007bff' : '#f8f9fa',
              color: currentPage === index + 1 ? '#fff' : '#000',
            }}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default AdminUserCheck;

