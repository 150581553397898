import React, { useState } from 'react';
import {Button} from 'react-bootstrap';
import AdminUserCheck from './AdminUserCheck';
import AdminUserFind from './AdminUserFind';
import AdminReportCheck from './AdminReportCheck';
import AdminStopUser from './AdminStopUser';
import AdminDeleteUser from './AdminDeleteUser';

function AdminPage() {
  const [activeTab, setActiveTab] = useState('UserCheck');

  return (
    <div style={{ position: 'absolute', left: 0, width: '100%' }}>
      <h1>USER PAGE</h1>
      <div style={{marginBottom: '2rem'}}>
        <Button className="btn btn-light" onClick={() => setActiveTab('UserCheck')}>사용자 정보</Button>
        <Button className="btn btn-light" onClick={() => setActiveTab('ReportCheck')}>신고 유저</Button>
 	<Button className="btn btn-light" onClick={() => setActiveTab('StopUser')}>정지된 유저</Button>
        <Button className="btn btn-light" onClick={() => setActiveTab('DeleteUser')}>삭제한 사용자</Button>
      </div>
      <div className="content-container" style={{marginBottom: '15px'}}>
        {activeTab === 'UserCheck' && (<><AdminUserCheck /><hr /><AdminUserFind /> </>)}
        {activeTab === 'ReportCheck' && (<><AdminReportCheck /><hr /><AdminUserFind /> </>)}
	{activeTab === 'StopUser' && (<><AdminStopUser /><hr /><AdminUserFind /> </>)}
        {activeTab === 'DeleteUser' && (<><AdminDeleteUser /></>)}
      </div>
    </div>
  );
}

export default AdminPage;

