import {Container, Row, Col, Button, Image, Ratio} from 'react-bootstrap';
import {PinAngle} from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import ClassView from './ClassView.js';

const fontSizeSmall={fontSize: '0.9rem', width: '100%', margin:'0', padding: '3px', textAlign: 'center'};
const fontSizeSmall2={fontSize: '0.6rem', width: '100%', marginRight:'0', backgroundColor: '#efefef', color:'black', fontWeight:'bold', border: '0'};
const buttonSpace0 = {margin: '0', padding: '0',fontSize: '0.6rem', width: '100%'};
const classBody = {marginBottom:'2px', borderRadius: '6px',fontSize:'0.6rem', marginLeft: '0', paddingLeft: '4px', paddingRight: '4px', border: '1px solid #999999'};
const classAll = {textAlign: 'center', marginRight: '1px', marginLeft:'1px', width: '38%', paddingRight: 0};
const fontSmall = {fontSize:'0.6rem'};
const marginTop2 = {marginTop: '2px', height: '48%'};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
};



function checkType(MyType){
        let category = 0;

        switch(MyType) {
                case 'Amity': category = "/ClassAmity.png"; break;
                case 'Exercise': category = "/ClassExercise.png"; break;
                case 'Hobby': category = "/ClassHobby.png"; break;
                case 'Etc': category = "/ClassEtc.png"; break;
                default: category = "/ClassEtc.png"; break;
        }   

        return category;
}


function MyLocationClass({mainClass, myUid}) { 


	//최근 3개의 데이터 돌려서 추출하기
	const [mainClassData, setMainClassData] = useState([]);

	useEffect(() => {
		if(mainClass && mainClass.length >0){
			setMainClassData(mainClass);
		}
	},[mainClass]);

	const ViewMainClassList = mainClassData.map((v, index)=> (
			<Col xs={5} key={index} style={classAll} onClick={() => handleViewClick(v.classId)}>
			    <Row style={{margin:0, marginRight: '10px'}}>
				<Row style={{margin:0}}>
				</Row>
				<Row style={{...classBody, margin:0}}>
				    {
					    myUid === v.cOpenUser ? 
					    <div style={{position: 'relative'}}>
						<img
					    	    src = '/crown.png'
					    	    alt='Crown Icon'
					    	    style={{position:'absolute', right:'5px', top:'5px', zIndex: 1, width: '15px', height: '15px'}}
					    	/>
					    </div>
					    : null
				    }
				    <Ratio aspectRatio="1x1" style={marginTop2}>
					<Image src={checkType(v.ctype)} fluid rounded style={{border: '1px solid', width: '50%', height: 'auto', margin: '0.5rem 0 0 0.5rem'}}/>
				    </Ratio>
				    <div style={{fontWeight:'bold',  textAlign: 'left', padding: '0 5px', fontSize: '12px', whiteSpace: 'normal'}}>{v.cTitle}</div>
				    <div style={{textAlign: 'left', padding: '0 2px'}}><Image style={{width: '15px'}} src='/userIcon.png' /> {v.cNowUser.length+1}/{v.cTotalNum}</div>
				    <div style={{color: 'gray', textAlign: 'left', padding: '0 2px', margin: '10px 0'}}>{formatDate(v.cOpenDate)}</div>
				</Row>
			    </Row>
			</Col>
	));

        //######################클릭하면 보여주는 부분#################
        const [viewClassCheck, setViewClassCheck] = useState(false);
        const [viewClassId, setViewClassId] = useState(null);

        const handleViewClick = (classId) => {
            setViewClassId(classId);// viewClassId 상태를 업데이트합니다.
            setViewClassCheck(!viewClassCheck);
        };

        const handleCloseView = () => {
                setViewClassCheck(!viewClassCheck);
        };
        //############################################################

	return(
	<div style={{padding: '0 10px'}}>
	<Row style={{margin:0, marginTop: '3rem'}}>
	    <Col xs={9}>
		<h4><b>우리동네 모임</b></h4>
	    </Col>
	    <Col xs={3} style={{fontSize: '0.7rem'}}>
		    <Link to='/myLoClass'>
			<Button variant="link" style={{...fontSizeSmall, textAlign: 'right'}}>
		    	    <Image src='/rightArrow.png' />
			</Button>
		    </Link>
	    </Col>
	</Row>
	    <div style={{ overflowX: "auto", whiteSpace: "nowrap", scrollbarWidth: "none", msOverflowStyle: "none",  WebkitOverflowScrolling: "touch"}}>
		<div style={{ display: "inline-flex", width: '100%' }}>
		    {ViewMainClassList.length > 0 ?(
			    ViewMainClassList.concat(
				    Array.from({ length: Math.max(0, 3 - ViewMainClassList.length) }).map((_, index) => (
					    <div key={`empty-${index}`} style={classAll}></div>
				    ))
			    )
		    ):(
                            <div style={{paddingTop: '2rem', textAlign: 'center', width: '100%'}}>
                            <Image src='/noClass.png' style={{width: '55px'}} />
                            <p style={{marginTop: '1rem', color: '#aaaaaa'}}>모임을 만들어보세요</p>
                            </div>
                        )}
		</div>
	    </div>
	{viewClassCheck && (
                <div>
                    <ClassView classId={viewClassId} onCloseView={handleCloseView}/>
                </div>
        )}

	</div>
	);
}

export default MyLocationClass;
