import {Row, Col, Button, Image, Ratio, Container, Modal, Form} from 'react-bootstrap';
import {X} from 'react-bootstrap-icons';
import {useState, useEffect} from 'react';
import {NotionRenderer} from 'react-notion-x';
import { Collection } from 'react-notion-x/build/third-party/collection';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'react-notion-x/src/styles.css'

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

const tableStyle={width: '100%'};
const titleStyle={fontWeight: 'bold', textAlign:'center', backgroundColor: '#eeeeee', borderRadius: '1rem', padding: '0.5rem'};
const contentDetailStyle={fontSize: '0.9rem', position:'absolute', top:0, left:0, width: '100%', height:'100vh', backgroundColor: 'white', padding:'1rem', textAlign:'left'};

function SettingNotion(){
	const navigate = useNavigate();
	const [notionData, setNotionData] = useState(null);

	return(
	<div>
	    <Row style={{textAlign: 'right', marginBottom: '5px', padding: '0 1rem', width: '100%'}} onClick={()=>navigate('/notice')}>
		<Col xs={10} style={{textAlign: 'left'}}>
		    <Image src='/announcement-02.png' />
		    <b style={{fontSize: '16px', paddingLeft: '8px'}}> 공지사항 </b>
		</Col>
		<Col style={{padding:0, textAlign: 'right'}} >
		    <Image src='/rightArrow.png'/>
		</Col>
	    </Row>
	</div>
	);
}

export default SettingNotion;
